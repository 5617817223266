<template>
  <div>
    <div v-if="profilesList.length">
      <div>
        <p class="text-size-m mb-m">
          Здесь перечислены профили в&nbsp;которых вы&nbsp;стали победителем или
          призером в&nbsp;прошлом году. Выберите, в&nbsp;финале каких профилей
          вы&nbsp;хотите участвовать в&nbsp;этом году. Важно определиться
          с&nbsp;профилями именно сейчас, иначе мы&nbsp;не&nbsp;сможем допустить
          вас на&nbsp;финал. Пожалуйста, не&nbsp;откладывайте!
        </p>
      </div>
      <div v-if="profilesLimit && profilesList.length > profilesLimit">
        (Максимум {{ profilesLimit }} {{ limitText }})
      </div>
      <div class="scroll-content profile-list mb-l">
        <ProfileCard
          v-for="item in profilesList"
          :id="item.id"
          :key="item.id"
          :selected="selected.includes(item.id)"
          :selectable="!profilesLimit || selected.length < profilesLimit"
          @select="handleSelectProfile">
          <div>{{ item.title }}</div>
          <template #meta>
            <div
              v-if="item.combinedTracks"
              class="tracks">
              <span class="text-bold">Трек НТО:</span> {{ item.combinedTracks }}
            </div>
          </template>
        </ProfileCard>
      </div>
      <div class="hr"></div>
      <div
        v-if="error || submitError"
        class="form-error mb-m">
        {{ error || submitError }}
      </div>
      <BaseButton @click.prevent="handleSubmit">Зарегистрироваться</BaseButton>
    </div>
    <div v-else>
      <p class="mb-l">Нет доступных профилей для выбора</p>
      <div class="hr"></div>
      <BaseButton
        theme="reg"
        @click.prevent="$emit('goBack')"
        >Назад</BaseButton
      >
    </div>
  </div>
</template>

<script>
import {
  JUNIOR_ALIAS,
  LOW_SCHOOL_ALIAS,
  SCHOOL_ALIAS,
  STUDENT_ALIAS,
} from "@/constants";
import ProfileCard from "@/components/registration/ProfileCard.vue";
import { numCases } from "@/utils";
/**
 * @component Выбор профилей для поздней регистрации финалистов прошлых лет
 */
export default {
  name: "FinalRegProfiles",
  components: {
    ProfileCard,
  },
  props: {
    profiles: {
      type: Array,
      default() {
        return [];
      },
    },
    role: {
      type: String,
      required: true,
      validator(value) {
        return [STUDENT_ALIAS, SCHOOL_ALIAS].includes(value);
      },
    },
    grade: {
      type: Number,
    },
    submitError: {
      type: String,
    },
  },
  data() {
    return {
      selected: [],
      error: "",
    };
  },
  computed: {
    tracks() {
      return Object.values(this.$store.state.tracks);
    },
    profilesList() {
      const { role, grade, profiles, currentAlias } = this;
      if (!profiles?.length || !role || (role !== STUDENT_ALIAS && !grade)) {
        return [];
      }
      return profiles
        .filter((n) => n.tracks.some((t) => t.alias === currentAlias))
        .map((n) => {
          return {
            ...n,
            combinedTracks: n.tracks.map((t) => t.title).join(", "),
          };
        });
    },
    currentTrack() {
      const { tracks, role, grade } = this;
      let track;
      if (role === STUDENT_ALIAS) {
        track = tracks.find((t) => t.alias === STUDENT_ALIAS);
      } else {
        track = tracks.find((t) => {
          return t.allowed_grades?.includes(grade);
        });
      }
      return track;
    },
    currentAlias() {
      if (this.role === STUDENT_ALIAS) {
        return STUDENT_ALIAS;
      } else if (this.grade >= 8 && this.grade < 10) {
        return LOW_SCHOOL_ALIAS;
      } else if (this.grade < 8) {
        return JUNIOR_ALIAS;
      } else {
        return SCHOOL_ALIAS;
      }
    },
    profilesLimit() {
      return this.currentTrack?.profile_limits;
    },
    limitText() {
      return numCases(["профиль", "профиля", "профилей"], this.profilesLimit);
    },
  },
  methods: {
    handleSubmit() {
      const len = this.selected.length;
      this.error = "";
      if (!len) {
        this.error = "Пожалуйста, выберите профиль.";
        return;
      }
      if (this.profilesLimit && this.profilesLimit < len) {
        this.error = `Превышен лимит выбранных профилей (${this.profilesLimit}). Пожалуйста, отмените выбор лишних профилей.`;
        return;
      }
      this.$emit("onSubmit", this.selected);
    },
    handleSelectProfile(profileId) {
      const idx = this.selected.indexOf(profileId);
      if (idx < 0) {
        this.selected.push(profileId);
      } else {
        this.selected.splice(idx, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.profile-list {
  border: 1px solid @lp-outline-color;
  border-radius: 4px;
  margin: 40px 0;
  max-height: 400px;
  max-height: 45vh;
}

.tracks {
  opacity: 0.75;
}
</style>
