<template>
  <div class="container">
    <div v-if="pending">
      <BaseSpinner brand />
    </div>
    <div v-else>
      <div
        v-if="!talentUser"
        class="row">
        <div class="col-md-8 col-sm-12 col-xs-12 col-md-offset-2">
          <RoleCard role="school">
            <template #label>Регистрация</template>
            <template #label_img>
              <img :src="currentImage" />
            </template>
            <template #body>
              <p class="text-size-m mb-s">
                Чтобы продолжить, войдите в&nbsp;учетную запись
                на&nbsp;платформе &laquo;Талант&raquo;.
              </p>
              <p class="text-size-m mb-l">
                Будьте внимательны! Это должна быть та&nbsp;же учетная запись,
                которую вы&nbsp;использовали в&nbsp;прошлом сезоне НТО. Если
                вы&nbsp;потеряли доступ к&nbsp;этой учетной записи, обратитесь
                в&nbsp;службу поддержки:
                <a
                  class="link"
                  :href="`mailto:${$store.state.supportEmail}?subject=%D0%A0%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8F%20%D1%84%D0%B8%D0%BD%D0%B0%D0%BB%D0%B8%D1%81%D1%82%D0%BE%D0%B2%20%D1%81%D0%B5%D0%B7%D0%BE%D0%BD%D0%B0%2020%2F21`"
                  >{{ $store.state.supportEmail }}</a
                >
              </p>
              <BaseButton
                tag="a"
                theme="reg"
                :href="loginUrl"
                >войти в учетную запись</BaseButton
              >
            </template>
          </RoleCard>
        </div>
      </div>
      <div
        v-else-if="userErrorMessage"
        class="row">
        <div class="col-md-8 col-sm-12 col-xs-12 col-md-offset-2">
          <RoleCard role="school">
            <template #label>Ошибка</template>
            <template #body>
              <p class="text-size-m mb-l">{{ userErrorMessage }}</p>
              <BaseButton
                v-if="isMentor"
                tag="router-link"
                theme="reg"
                :to="{ name: 'mentor-index' }"
                >В кабинет наставника</BaseButton
              >
              <BaseButton
                v-else-if="isParticipant"
                tag="router-link"
                theme="reg"
                :to="{ name: 'user-index' }"
                >В кабинет участника</BaseButton
              >
            </template>
          </RoleCard>
        </div>
      </div>
      <div
        v-else-if="userRegError"
        class="row">
        <div class="col-md-8 col-sm-12 col-xs-12 col-md-offset-2">
          <RoleCard role="school">
            <template #label>Ошибка</template>
            <template #body>
              <p class="text-size-m mb-l">
                {{ userRegError }}
              </p>
            </template>
          </RoleCard>
        </div>
      </div>
      <RoleCard
        v-else
        :role="role">
        <template #label>Регистрация</template>
        <template #label_info
          >Шаг {{ step }}&nbsp;из&nbsp;{{ steps.length }}&nbsp;&mdash;&nbsp;{{
            steps[step - 1].title
          }}</template
        >
        <template #label_img>
          <img :src="currentImage" />
        </template>
        <template #body>
          <BaseLoadingBox
            :pending="formPending"
            theme="reg">
            <div v-if="currentStep === 'form'">
              <UserForm
                :talent-user="talentUser"
                :role="role"
                :is-finalist="true"
                @goBack="handleGoBack"
                @onSubmitFinalist="handleSetForm">
                <template #top>
                  <div v-if="roles.length > 1">
                    <div class="row mb-m">
                      <div class="col-md-6 col-xs-12">
                        <label class="form-label">Трек олимпиады *</label>
                        <BaseSelect
                          v-model="selectedRole"
                          placeholder="Школа"
                          :options="roles"
                          theme="reg"
                          :allow-empty="false"
                          track-by="role"
                          label="title"
                          :searchable="false" />
                      </div>
                    </div>
                    <div class="hr"></div>
                  </div>
                </template>
              </UserForm>
            </div>
            <div v-if="currentStep === 'profiles'">
              <FinalRegProfiles
                :profiles="allowedProfiles"
                :role="role"
                :grade="form.grade"
                :submit-error="submitError"
                @goBack="handleGoBack"
                @onSubmit="handleSelectProfiles" />
            </div>
          </BaseLoadingBox>
        </template>
      </RoleCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserForm from "@/components/registration/UserForm.vue";
import RoleCard from "@/components/registration/RoleCard.vue";
import FinalRegProfiles from "@/components/registration/FinalRegProfiles.vue";
import {
  JUNIOR_ALIAS,
  LOW_SCHOOL_ALIAS,
  PARTICIPANT_ROLE,
  SCHOOL_ALIAS,
  STUDENT_ALIAS,
} from "@/constants";
import { request } from "@/services/api";
const ROLE_IMAGES = {
  school: require(`@/assets/images/reg_school.svg`),
  mentor: require(`@/assets/images/reg_mentor.svg`),
  student: require(`@/assets/images/reg_student.svg`),
};
export default {
  name: "FinalRegIndex",
  components: {
    RoleCard,
    UserForm,
    FinalRegProfiles,
  },
  metaInfo() {
    return {
      title: "Регистрация финалистов",
    };
  },
  data() {
    return {
      userRegError: "",
      step: 1,
      selectedProfiles: [],
      form: {},
      steps: [
        {
          title: "Заполнение данных",
          id: "form",
        },
        {
          title: "Выбор профиля",
          id: "profiles",
        },
      ],
      pending: true,
      allowedProfiles: [],
      formPending: false,
      submitError: "",
      selectedRole: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
    }),
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    loginUrl() {
      return `${this.$store.state.clientApiPrefix}/auth/go?next=${this.$route.fullPath}`;
    },
    /**
     * является ли пользователь участником текущего сезона
     */
    isParticipant() {
      const { user } = this;
      return user?.id && Boolean(user?.participant);
    },
    /**
     * Текст причины, почему текущему юзеру
     * нельзя воспользоваться этой формой
     */
    userErrorMessage() {
      const { pending, isMentor, isParticipant, allowedProfiles } = this;
      if (pending) return undefined;
      if (isMentor) {
        return "Вы не можете зарегистрироваться как финалист прошлого сезона, потому что являетесь наставником.";
      }
      if (isParticipant) {
        return "Вы уже зарегистрированы как участник.";
      }
      if (!allowedProfiles.length) {
        return "Мы не нашли среди ваших достижений, достижения финалиста прошлого сезона.";
      }
      return undefined;
    },
    currentStep() {
      const { step, steps } = this;
      return steps[step - 1]?.id;
    },
    roles() {
      const { allowedProfiles } = this;
      const allowStudent = allowedProfiles.some((n) =>
        n.tracks.some((t) => t.alias === STUDENT_ALIAS)
      );
      const allowSchool = allowedProfiles.some((n) =>
        n.tracks.some((t) =>
          [SCHOOL_ALIAS, LOW_SCHOOL_ALIAS, JUNIOR_ALIAS].includes(t.alias)
        )
      );
      const result = [];
      if (allowSchool) {
        result.push({
          title: "Школьный трек (5-11 классы)",
          role: SCHOOL_ALIAS,
        });
      }

      if (allowStudent) {
        result.push({
          title: "Студенческий трек",
          role: STUDENT_ALIAS,
        });
      }
      return result;
    },
    role() {
      const { selectedRole } = this;
      return selectedRole?.role;
    },
    currentImage() {
      const { role } = this;
      return ROLE_IMAGES[role] || ROLE_IMAGES["student"];
    },
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * @param {Number[]} profiles
     */
    async handleSelectProfiles(profiles) {
      this.selectedProfiles = profiles;
      this.formPending = true;
      const payload = {
        ...this.form,
        profiles: [...profiles],
      };
      try {
        await request({
          method: "POST",
          url: "/participant/late",
          data: payload,
        });
        window.dataLayer?.push({
          event: "nto_regform_choose_role",
          eventCategory: "nto_regform",
          eventAction: "late_registration",
          userRole: this.role === STUDENT_ALIAS ? "student" : "pupil",
          userChoice: this.role,
        });
        await this.$store.dispatch("user/getMe");
        this.$router.push({
          name: "user-index",
        });
      } catch (error) {
        this.submitError = error.message;
      }
      this.formPending = false;
    },
    handleSetForm(form) {
      this.form = form;
      this.step++;
    },
    async regUser() {
      const { data } = await request({
        method: "POST",
        url: "/user/register",
        data: {
          role: PARTICIPANT_ROLE,
        },
      });
      this.$store.commit("user/SET_STATE", {
        key: "user",
        value: data,
      });
    },
    async getAllowedProfiles() {
      const { data } = await request({
        method: "GET",
        url: "/participant/late/allowed_profiles",
      });
      this.allowedProfiles = data.items;
    },
    handleGoBack() {
      this.step > 1 && --this.step;
    },
    async init() {
      try {
        await this.$store.dispatch("getSeason");
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить информацию о текущем сезоне.",
          message: error.message,
          status: error.status,
          report: true,
          url: "getSeason",
        });
        return;
      }
      try {
        this.pending = true;
        // получаем данные о юзере из таланта и ОНТИ
        await this.$store.dispatch("user/getMe");
      } catch (error) {
        // если Онти-Юзера нет, но пользователь авторизован в таланте
        // то зарегистрируем его как нового пользователя ОНТИ.
        if (this.talentUser && error.status === 404) {
          try {
            await this.regUser();
          } catch (error) {
            this.showErrorModal({
              content: "Не удалось создать учетную запись пользователя.",
              message: error.message,
              status: error.status,
            });
            this.pending = false;
            return;
          }
        } else {
          // Во всех остальных случаях останавливаем init
          // и просим юзера авторизоваться
          this.pending = false;
          return;
        }
      }
      // Надо еще проверить, что пользователь не является
      // участником текущего сезона и не является ментором
      if (this.isParticipant || this.isMentor) {
        this.pending = false;
        return;
      }

      // если пользователя получили, и он еще не зареган как участник,
      // на текущий сезон, то попробуем запросить список профилей
      // которые доступны ему для регистрации
      if (this.user?.id && !this.user.participant) {
        try {
          await this.getAllowedProfiles();
          // Получаем список ролей на основе профилей
          const roles = this.roles;
          if (roles.length) {
            this.selectedRole = roles[0];
          }
        } catch (error) {
          this.showErrorModal({
            content:
              "Не удалось получить Профили доступные для регистрации, Пожалуйста, сообщите нам об этой ошибке.",
            message: error.message,
            status: error?.response?.status,
            report: true,
          });
        }
      }
      try {
        await this.$store.dispatch("getTracks");
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить треки олимпиады.",
          message: error.message,
          status: error?.response?.status,
          report: true,
        });
      }
      this.pending = false;
    },
  },
};
</script>
